import React from "react";
import { TextInputProps } from "./types";
import { InputAdornment, TextField } from "@mui/material";

const TextInput = ({
  type,
  title,
  value,
  onChange,
  fieldName,
  className,
  placeholder,
  defaultValue,
  leftElement,
  rightElement,
  errorMessage,
  ...props
}: TextInputProps) => {
  const [presentingError, setPresentingError] = React.useState<string>();
  const [internalValue, setInternalValue] = React.useState<string | undefined>(
    value ?? ""
  );

  React.useEffect(() => {
    setPresentingError(errorMessage);
  }, [errorMessage]);

  React.useEffect(() => {
    setPresentingError(undefined);
    setInternalValue(value ?? "");
  }, [value]);

  const valueChanged = (newValue: string) => {
    setInternalValue(newValue);
    onChange?.(newValue);
  };

  const hasError = !!presentingError && presentingError.length > 0;

  return (
    <div className={`relative mt-5 ${className}`}>
      <TextField
        id={`textinput_${fieldName}`}
        type={type}
        label={title}
        name={fieldName}
        variant="outlined"
        value={internalValue}
        defaultValue={defaultValue}
        className={`w-full rounded-md text-base !font-normal`}
        InputLabelProps={{className: "text-base"}}
        onChange={(ev) => valueChanged(ev.target.value)}
        placeholder={placeholder}
        error={hasError}
        InputProps={{
          startAdornment: leftElement ? (
            <InputAdornment position="end">{leftElement}</InputAdornment>
          ) : undefined,
          endAdornment: rightElement ? (
            <InputAdornment position="end">{rightElement}</InputAdornment>
          ) : undefined,
        }}
        {...props}
      />
      {hasError && (
        <p className="text-error-red font-regular text-left">
          {presentingError}
        </p>
      )}
    </div>
  );
};

export default TextInput;
